export type validationResponse = {
  result: boolean;
  message: string;
};

const isValidEmailFormat = (email: string): validationResponse => {
  const trimmedEmail = email.trim();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const result = emailRegex.test(trimmedEmail);
  return {
    result,
    message: result ? "" : "Invalid email format",
  };
};

const isEmpty = (val: any) => {
  const result = val == null || !(Object.keys(val) || val).length;
  return {
    result,
    message: result ? "Empty Content" : "",
  };
};

const isValidEmail = (content: string) => {
  const isEmptyResp = isEmpty(content);
  if (isEmptyResp.result)
    return {
      result: false,
      message: "Email is Required",
    }; //false

  const isValidEmailResp = isValidEmailFormat(content);
  if (!isValidEmailResp.result) return isValidEmailResp; //false

  return {
    result: true,
    message: "",
  };
};

export { isValidEmail, isEmpty, isValidEmailFormat };
